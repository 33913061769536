import { AxiosPromise } from 'axios';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { OdataItems } from '@/shared/model/OdataItems';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';
import { Route } from 'vue-router';

const name = 'import-table-view';
const logger = new Logger(name);

@Component({ name: name, components: { D4ySwitch } })
export default class ImportTableView extends Vue {
  @PropSync('file', {
    default: () => {
      return undefined;
    },
  })
  private filex?: any;
  mounted() {
    this.items.searchParams.filter = '';
    this.currentFile = this.filex;
  }
  @Prop()
  private updateChangeClearBeforeImport!: (arg0: any) => AxiosPromise<any>;
  @Prop({ default: false, type: Boolean })
  private NotifyBeforeUpdateChangeClearBeforeImport!: boolean;
  @Prop()
  private getItemsImportSetting!: () => AxiosPromise<any>;
  @Prop()
  private itemsImportSetting!: any;
  @Prop()
  private inProgressUpdateItemImportSetting!: () => boolean;

  @Prop()
  private getItems!: ({ searchParams: SearchParams }: any) => AxiosPromise<any>;
  @Prop()
  private items!: OdataItems<any>;
  @Prop({ default: '' })
  private structureForImport!: string;
  @Prop({ default: '' })
  private subtitle!: string;

  files = [];
  @Prop()
  private headers!: any;

  async OnChangeClearBeforeImport(newv: boolean, oldv: boolean) {
    if (this.NotifyBeforeUpdateChangeClearBeforeImport && newv) {
      await this.$message.open(`${this.$t('warning__brands_title')}`, `${this.$t('warning__brands_text')}`, {
        cancelColor: 'error',
        okColor: 'grey',
        okText: this.$t('btn_ok'),
      });
      logger.debug('message show end');
    }
    await this.updateChangeClearBeforeImport({ value: newv, old: oldv });
  }
  //#region uploadfile
  @Prop()
  private uploadItems!: any;
  private currentFile: string | null = null;
  private currentError: string = '';
  progress = 0;
  selectFile(file: any) {
    this.progress = 0;
    this.currentFile = file;
    this.currentError = '';
  }
  get isVisibleUploadBtn(): boolean {
    if (this.currentFile != null && this.progress == 0) return true;
    if (this.currentFile != null && this.currentError != '' && this.progress == 100) return true;
    return false;
  }
  async upload() {
    if (!this.currentFile) {
      return;
    }
    this.filex = this.currentFile;
    await this.uploadItems({
      fileForImport: this.currentFile,
      onUploadProgress: (event: any) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      },
    })
      .then((result: any) => {
        this.reloadData();
        this.$emit('upload:finished');
      })
      .catch((e: any) => {
        this.currentError = e?.errors?.join(',');
      });
  }
  //#endregion

  //#region search update
  @Watch('items.searchParams.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }
  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('items.searchParams.dataOption.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      this.reloadData();
    }
  }
  @Watch('items.searchParams.dataOption.sortDesc', { deep: true })
  public onOptionsSortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    if (oldValAsString != newValAsString) {
      this.reloadData();
    }
  }
  @Watch('items.searchParams.dataOption.page', { deep: true })
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    this.reloadData();
  }

  private onOptionsChanged(newVal: any) {
    this.reloadData();
  }
  async reloadData() {
    await Promise.all([this.getItems({ searchParams: this.items.searchParams }), this.getItemsImportSetting()]);
  }
  //#endregion
  /**
   * Load the billing info before rendering component.
   */
  beforeRouteEnter(to: Route, from: Route, next: any) {
    logger.log('beforeRouteEnter');
    next(async (vm: any) => {
      await vm.reloadData();
    });
  }

  handleSelectFile() {
    const fileUpload = document.getElementById('fileUpload');
    if (fileUpload != null) {
      fileUpload.click();
    }
  }
}
