import { render, staticRenderFns } from "./d4y-switch.html?vue&type=template&id=2b95dc36&scoped=true&external"
import script from "./d4y-switch.ts?vue&type=script&lang=ts&external"
export * from "./d4y-switch.ts?vue&type=script&lang=ts&external"
import style0 from "./d4y-switch.scss?vue&type=style&index=0&id=2b95dc36&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b95dc36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VSwitch})
