import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import allocationSetting, { AllocationSetting } from '@/shared/model/allocationSetting';
import allocationOfficesImportSetting, {
  AllocationOfficesImportSetting,
} from '@/shared/model/allocationOfficesImportSetting';
import { OdataItems } from '@/shared/model/OdataItems';
import { AllocationOffice, AllocationOfficeGroupByAllowedBrands, Value } from '@/shared/model/allocationOffice';
import EditOfficesPermittedDialog from './edit-offices-permitted-dialog/edit-offices-permitted-dialog.vue';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';

const name = 'offices-view';
const logger = new Logger(name);
const allocationSettingModule = namespace('allocationSetting');
const allocationOfficesImportSettingModule = namespace('allocationOfficesImportSetting');
const allocationOfficeModule = namespace('allocationOffice');
const organizationModule = namespace('organization');
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

@Component({ name: name, components: { EditOfficesPermittedDialog, D4ySwitch, ImportTableView } })
export default class OfficesView extends Vue {
  @allocationSettingModule.Action('updateAllocationSetting')
  private updateAllocationSetting!: any;
  @allocationSettingModule.Action('getAllocationSettingCurrent')
  private getAllocationSetting!: any;
  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @allocationOfficesImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @allocationOfficesImportSettingModule.Getter('getInProgressUpdateAllocationOfficesImportSetting')
  private inProgressUpdateAllocationOfficesImportSetting!: AllocationOfficesImportSetting;
  @allocationOfficesImportSettingModule.Action('getAllocationOfficesImportSetting')
  private getAllocationOfficesImportSetting!: any;
  @allocationOfficesImportSettingModule.Getter('getAllocationOfficesImportSetting')
  private allocationOfficesImportSetting!: AllocationOfficesImportSetting;
  @allocationOfficesImportSettingModule.Getter('getAllocationOfficesImportSettingsIsLoading')
  private allocationOfficesImportSettingsIsLoading!: AllocationOfficesImportSetting;
  @allocationOfficeModule.Action('getAllocationOffices')
  private getAllocationOffices!: () => Promise<Value>;
  @allocationOfficeModule.Getter('getAllocationOffices')
  private allocationOffices!: OdataItems<AllocationOffice>;
  @allocationOfficeModule.Action('uploadAllocationOffice')
  private uploadAllocationOffice!: any;
  @allocationOfficeModule.Getter('getIsImporting')
  private isImporting!: boolean;
  @allocationOfficeModule.Action('getAllAllocationOffices')
  private getAllAllocationOffices!: () => Promise<any>;
  @organizationModule.Getter('getOrganization')
  private organization!: any;

  officeDigits = [
    { id: 1, text: '1 Stellig' },
    { id: 2, text: '2 Stellig' },
  ];
  editOfficesAllowed() {
    this.openDatevSettingDialog(this.allocationSetting);
  }
  updateOfficesAllowed(item: AllocationSetting) {
    this.updateAllocationSetting(item);
  }
  uploadFinished() {
    this.getAllAllocationOffices().then(() => {
      //this.openDatevSettingDialog(this.allocationSetting);
    });
  }
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('office_number'), value: 'officeNumber', sortable: false },
      { text: this.$t('name'), value: 'name', sortable: false },
      { text: this.$t('linked_brands'), value: 'data.allowedBrands', sortable: false },
    ];
    return headers;
  }

  //#region Dialog logic
  dialogAddEdit = {
    show: false,
    model: {},
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  @allocationOfficeModule.Action('updateAllocationOfficeGroupByAllowedBrands')
  private updateAllocationOfficeGroupByAllowedBrands!: (
    item: AllocationOfficeGroupByAllowedBrands[]
  ) => Promise<string>;
  async dialogAddEditOnUpdate(item: AllocationOfficeGroupByAllowedBrands[]) {
    this.updateAllocationOfficeGroupByAllowedBrands(item).then(() => {
      this.dialogAddEdit.show = false;
      this.getAllocationOffices();
      this.getAllAllocationOffices();
    });
  }

  private async openDatevSettingDialog(status: any) {
    this.dialogAddEdit.model = { ...status };
    this.dialogAddEdit.show = true;
  }
  //#endregion
  onChangeOfficeDigits() {
    this.updateOfficesAllowed(this.allocationSetting);
  }

  async mounted() {}
}
