import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { OdataItems } from '@/shared/model/OdataItems';
import { AllocationOffice, AllocationOfficeGroupByAllowedBrands } from '@/shared/model/allocationOffice';
import { namespace } from 'vuex-class';
import { AllocationBrand } from '@/shared/model/allocationBrand';
import { AxiosResponse } from 'axios';

const name = 'edit-offices-permitted-dialog';
const logger = new Logger(name);
const allocationOfficeModule = namespace('allocationOffice');
const allocationBrandModule = namespace('allocationBrand');

@Component({
  name: name,
  components: {},
})
export default class EditOfficesPermittedDialog extends Vue {
  @allocationOfficeModule.Getter('getAllAllocationOffices')
  private allocationOffices!: AllocationOffice[];
  @allocationOfficeModule.Action('uploadAllocationOffice')
  private uploadAllocationOffice!: any;
  @allocationBrandModule.Getter('getAllAllocationBrands')
  private allocationBrands!: AllocationBrand[];
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => {} })
  private value!: any;

  @allocationOfficeModule.Action('getAllocationOfficeGroupByAllowedBrands')
  private getAllocationOfficesGroupByAllowedBrands!: any;
  @allocationOfficeModule.Getter('getAllocationOfficeGroupByAllowedBrands')
  private allocationOfficeGroupByAllowedBrands!: AllocationOfficeGroupByAllowedBrands[];
  @Watch('dialog')
  onDialogChange() {
    this.getAllocationOfficesGroupByAllowedBrands().then(() => {
      this.editItem = this.allocationOfficeGroupByAllowedBrands.map((x) => {
        return {
          officesAndBranch: {
            offices: x.allocationOfficesIds,
            brands: x.allocationOfficeData?.allowedBrands ?? [],
            searchInput: '',
          },
        };
      });
    });
  }
  editItem: { officesAndBranch: { offices: string[]; brands: string[]; searchInput: string } }[] = [];
  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit(
      'click:update',
      this.editItem.map((x) => {
        return {
          allocationOfficeData: { AllowedBrands: x.officesAndBranch.brands },
          allocationOfficesIds: x.officesAndBranch.offices,
        };
      })
    );
  }
  filtredAllocationOfficesItems(index: number) {
    const selectedOffices: string[] = [];
    this.editItem.forEach((x, i) => {
      if (i != index) x.officesAndBranch.offices.forEach((b) => selectedOffices.push(b));
    });
    return this.allocationOffices.filter((x) => !selectedOffices.includes(x.id));
  }
  filtredAllocationBrandsItems(index: number) {
    const selectedBrands: string[] = [];
    this.editItem.forEach((x, i) => {
      if (i != index) x.officesAndBranch.brands.forEach((b) => selectedBrands.push(b));
    });
    return this.allocationBrands; //.filter((x) => !selectedBrands.includes(x.brandNumber));
  }
  get isAddDisabled() {
    const anyNotExistentValue = -2; //mistic number not contained in positive index of editItem
    const avalableForSelectOfficesLength: number = this.filtredAllocationOfficesItems(anyNotExistentValue).length;
    const editItemContainsEmpty =
      this.editItem.map((x) => x.officesAndBranch.offices).findIndex((x) => x.length == 0) != -1;
    return avalableForSelectOfficesLength == 0 || editItemContainsEmpty;
  }
  private onClickAdd() {
    this.editItem.push({ officesAndBranch: { offices: [], brands: [], searchInput: '' } });
  }
  private onClickDelete(index: number) {
    this.editItem.splice(index, 1);
  }
  private onBrandKeyUp(event: any, index: number) {
    if (event.key == ',') {
      const key = event.target.value.replace(',', '');
      if (this.allocationBrands.find((x) => x.brandNumber == key)) {
        this.editItem[index].officesAndBranch.brands.push(key);
        this.editItem[index].officesAndBranch.searchInput = '';
      }
    }
  }
}
