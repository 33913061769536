import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component({ name: 'd4y-switch', components: {} })
export default class D4ySwitch extends Vue {
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;
  @Prop({ default: 'primary', type: String })
  private color!: string;

  @PropSync('value', {
    default: () => {
      return false;
    },
    type: [String, Number, Boolean],
  })
  private modelValue!: string | boolean | number;

  onChange(event: any) {
    this.$emit('change', event, this.modelValue);
  }
}
